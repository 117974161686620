
import {
  defineComponent, computed, ref, defineAsyncComponent
} from 'vue'
import { useModes } from '@/compositions/modes'
import type { TableHeaders } from '@/definitions/shared/types'
import SettingsBlock from '@/components/shared/templates/SettingsBlock.vue'
import { formatDate } from '@/services/dateTimeService'

const DlcRegistrationButton = defineAsyncComponent(() => import('@/components/pages/services/numbers/dlcRegistration/DlcRegistrationButton.vue'))
const DlcRegistrationEmpty = defineAsyncComponent(() => import('@/components/pages/services/numbers/dlcRegistration/DlcRegistrationEmpty.vue'))
const DlcRegistrationTable = defineAsyncComponent(() => import('@/components/pages/services/numbers/dlcRegistration/DlcRegistrationTable.vue'))

export default defineComponent({
  components: {
    SettingsBlock,
    DlcRegistrationButton,
    DlcRegistrationEmpty,
    DlcRegistrationTable,
  },
  setup() {
    const { isEmptyMode, isEmptyHalfMode } = useModes()

    const brand = computed(() => (isEmptyMode.value)
      ? []
      : [{
          idBrand: 'BZ3LVQP',
          status: {
            name: 'Registered',
            color: 'green',
            outline: true,
          },
          company: 'Organization Ltd.',
          lastUpdated: '2022-07-16T02:55:06.755Z',
        }])

    const brandTableHeaders = ref<TableHeaders[]>([
      { text: 'Brand ID', value: 'idBrand', width: '20%' },
      { text: 'Status', value: 'status-slot', width: '20%' },
      { text: 'Company', value: 'company', width: '50%' },
      {
        text: 'Last updated',
        value: 'lastUpdated',
        format: (val: string) => formatDate(val),
        width: '10%',
      },
    ])

    const campaign = computed(() => (isEmptyMode.value || isEmptyHalfMode.value)
      ? []
      : [{
          idCampaign: 'BZ3LVQP',
          status: {
            name: 'Registered',
            color: 'green',
            outline: true,
          },
          cost: '$40 per quarter',
          nextRenewal: '2022-07-12T02:55:06.755Z',
          lastUpdated: '2022-07-16T02:55:06.755Z',
        }])

    const campaignTableHeaders = ref<TableHeaders[]>([
      { text: 'Campaign ID', value: 'idCampaign', width: '20%' },
      { text: 'Status', value: 'status-slot', width: '20%' },
      { text: 'Cost', value: 'cost', width: '30%' },
      {
        text: 'Next renewal',
        value: 'nextRenewal',
        format: (val: string) => formatDate(val),
        width: '20%',
      },
      {
        text: 'Last updated',
        value: 'lastUpdated',
        format: (val: string) => formatDate(val),
        width: '10%',
      },
    ])

    return {
      brand,
      brandTableHeaders,
      campaign,
      campaignTableHeaders,
    }
  },
})
